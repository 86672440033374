<template>
  <div class="card-sections-block-footer">
    <total-evaluate-entity
      v-if="isSeeTotalEntityEvaluate"
      :entity-evaluate-key="evaluationTypes['TYPE_EFFICIENCY']"
      :entity-evaluations="evaluations[evaluationTypes['TYPE_EFFICIENCY']]"
      title-name="ИТОГО"
      :evaluate-key="totalEvaluations['TOTAL_EVALUATE_EFFICIENCY']"
      permission="card.total_evaluate_efficiency_update"
    ></total-evaluate-entity>
    <card-competencies
      v-if="$canAndRulesPass(card, 'competencies.see')"
      :card-id="card.id"
    ></card-competencies>
    <total-evaluate-entity
      v-if="isSeeTotalEntityEvaluate"
      :entity-evaluate-key="evaluationTypes['TYPE_TOTAL_COMPETENCE']"
      :entity-evaluations="evaluations[evaluationTypes['TYPE_TOTAL_COMPETENCE']]"
      title-name="ИТОГО ПО КАРТЕ"
      :evaluate-key="totalEvaluations['TOTAL_EVALUATE_COMPETENCE']"
      permission="card.total_evaluate_competence_update"
    ></total-evaluate-entity>
    <total-evaluate-entity
      v-if="isSeeTotalEntityEvaluate"
      :entity-evaluate-key="evaluationTypes['TYPE_FINAL_EFFICIENCY']"
      :entity-evaluations="evaluations[evaluationTypes['TYPE_FINAL_EFFICIENCY']]"
      title-name="ИТОГО ПО КАРТЕ"
      :evaluate-key="totalEvaluations['TOTAL_EVALUATE_FINAL_EFFICIENCY']"
      permission="card.total_evaluate_final_efficiency_update"
      evaluation-name="Итоговая оценка"
    ></total-evaluate-entity>
    <div class="button-block">
      <el-popconfirm
          v-if="isShowButton"
          confirm-button-text="Да"
          cancel-button-text="нет"
          icon="el-icon-question"
          title="Вы уверены, что хотите ознакомить с оценкой пользователя?"
          @confirm="enableTotalEvaluationsVisibility"
      >
        <template #reference>
          <el-button
              type="warning"
              size="mini"
              icon="fas el-icon-view"
              :loading="loading"
          >
            Ознакомить Сотрудника с Оценкой
          </el-button>
        </template>
      </el-popconfirm>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {downloader} from "@/mixins/downloader";
import CloneElementsFromCardModal from "@/components/cards/CloneElementsFromCardModal.vue";
import HistoryModal from "@/components/HistoryModal.vue";
import StartDynamicStageButton from "@/components/cards/StartDynamicStageButton.vue";
import CardCompetencies from "@/componentsCompany/smz/CardCompetencies.vue";
import TotalEvaluateEntity from "@/componentsCompany/smz/TotalEvaluateEntity.vue";
import requestSender from "@/api/base/requestSender";

export default {
  name: "card-sections-block-footer",
  mixins: [downloader],
  components: {StartDynamicStageButton, HistoryModal, CloneElementsFromCardModal, CardCompetencies, TotalEvaluateEntity},
  props: {},
  created() {
    this.getEvaluations();
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('card', {
      card: 'card'
    }),
    ...mapGetters('card/stages', {
      currentStage: 'currentStage',
    }),
    ...mapGetters('card/sections', {
      sections: 'sections',
      sectionsElements: 'sectionsElements',
    }),
    isSeeTotalEntityEvaluate() {
      if (this.user.id === this.card.user_id) {
        return this.card.info.total_evaluations_visibility;
      } else {
        return Object.keys(this.evaluations).length > 0
            && ((this.currentStage && this.currentStage.type === this.$constants.stage.types.TYPE_ASSESSMENT)
            || (this.card.status === this.$constants.card.statuses.STATUS_COMPLETED));
      }
    },
    isShowButton() {
      return this.card.info
        && !this.card.info.total_evaluations_visibility
        && this.$canAndRulesPass(this.card, 'card.total_evaluations_visibility_update')
        && this.card.status === this.$constants.card.statuses.STATUS_COMPLETED;
    },
  },
  watch: {},
  data() {
    return {
      evaluations: {},
      evaluationTypes: this.$companyConfiguration.settings.getEnum('evaluation_types'),
      totalEvaluations: this.$companyConfiguration.settings.getEnum('evaluation_total_evaluations'),
      loading: false,
    }
  },
  methods: {
    async getEvaluations() {
      requestSender('get', 'evaluation/search', {})
        .then(({evaluations}) => {
          this.evaluations = evaluations;
        })
    },
    enableTotalEvaluationsVisibility() {
      this.loading = true;

      requestSender('post', 'card/enable-total-evaluations-visibility', {
        card_id: this.card.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Статус выставлен',
            message: 'Итоговые оценки успешно открыты'
          });
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('card/loadCard');
        });
    },
  }
}
</script>

<style lang="scss">
  .button-block {
    display: flex;
    justify-content: flex-end;
  }
</style>
<template>
  <div>
    <div class="comment-preview" @click="openConversation">
      <el-tag style="float:right" size="mini">{{ commentsCountLocal }}</el-tag>
      <div v-if="lastCommentLocal">
        <div class="last-message-header">
          {{ new Date(lastCommentLocal.created_at).toLocaleString($companyConfiguration.helpers.getDateLocale()) }}
        </div>
        <div class="last-message-text">
          {{ lastCommentLocal.text }}
          <div v-if="lastCommentLocal.attachments">Файл</div>
        </div>
      </div>
      <div
        v-else
        class="no-last-message"
      >
        Нет комментариев
      </div>
    </div>
    <el-dialog
      destroy-on-close
      title="Комментарии"
      :visible.sync="showDialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <comments-block
        :disabled="!$canAndRulesPass(scope.row, 'comment.'+stage)"
        :entity-type="entityType"
        :entity-id="scope.row.id"
        :entity-segment="stage"
        @comment-added="commentAdded"
      ></comments-block>
    </el-dialog>
  </div>
</template>

<script>
import CommentsBlock from "@/components/comments/CommentsBlock";
import {mapGetters} from "vuex";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'competence-comment-table-column',
  components: {CommentsBlock},
  mixins: [ElementPropTableColumn],
  props: {
    stage: {type: String, required: true},
    entityType: {type: String, required: true},
    lastComment: {type: Object},
    commentsCount: {type: Number, default: 0},
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      lastCommentLocal: this.lastComment,
      commentsCountLocal: this.commentsCount,
    }
  },
  watch: {
    'lastComment': function () {
      this.lastCommentLocal = this.lastComment;
    },
    'commentsCount': function () {
      this.commentsCountLocal = this.commentsCount;
    }
  },
  computed: {
    ...mapGetters('card', {
      card: 'card',
    }),
  },
  methods: {
    openConversation() {
      this.showDialog = true;
    },
    commentAdded(comment) {
      this.lastCommentLocal = comment;
      this.commentsCountLocal = this.commentsCountLocal + 1;
      this.$store.dispatch('card/errors/loadErrors');
      this.$store.dispatch('card/loadCardData');
    }
  }
}
</script>

<style scoped>
.comment-preview {
  line-height: 15px;
  cursor: pointer;
}

.comment-preview .last-message-header {
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #b1b1b2;
}

.comment-preview .last-message-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.comment-preview .no-last-message {
  color: #b1b1b2;
}
</style>
